import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'rsuite/dist/styles/rsuite-default.css';
import { Button } from 'rsuite';
import { Navbar } from 'rsuite';
import { Nav, Dropdown } from 'rsuite';
import { DateRangePicker } from 'rsuite';

//import SmileyFace from './SmileyFace'
import PnlBarChart from './PnlBarChart'
import { useData } from './useData';

const instance = (
    <Nav>
      
      <Nav.Item>News</Nav.Item>
      <Nav.Item>Solutions</Nav.Item>
      <Nav.Item>Products</Nav.Item>
      <Nav.Item>About</Nav.Item>
    </Nav>
  );

const App = () => {

    const data = useData();

    if (!data) {
        return <pre>Loading...</pre>;
    }

    console.log(data);

    return ( <div>
        
     <PnlBarChart pnldata={data}/></div> )
}

export default App;

