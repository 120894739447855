import React, { Component,useState, useEffect } from 'react'

import * as d3 from 'd3'

export const useData = () => {
    const [data, setData] = useState(null);
  
    useEffect(() => {

     
      d3.xml("https://www.gcs-ch.com/node-red/xmltest?startdate=2020-05-01&enddate=2020-05-14").then(function(data) {
        let pnl=xmlToJson(data);
        let x;
        let pnlbybroker=[];
        let totalpnl=0.0;

        pnl.pnl.row.forEach(x => 
        {

            let pnl={};
            pnl.broker =x.BROKER['#text']?x.BROKER['#text']:"NA";
            pnl.fee =  parseFloat(x.FEE['#text']?x.FEE['#text']:"0").toFixed(2);
            pnl.curr = x.CURR['#text']?x.CURR['#text']:"NA";
            pnl.valid = ( x.CURR['#text'] && x.BROKER['#text'] && ( ( (typeof (x.BUYBRO) !== 'undefined') ) || ( (typeof (x.SELLBRO) !== 'undefined') ) )  )? "T":"F";
            pnl.fx =  x.FX['#text'];

            if ( typeof (x.BUYBRO) !== 'undefined' )
            {
                pnl.bro=   x.BUYBRO['#text']?x.BUYBRO['#text']:"0";
                pnl.type = "BUY";
            }

            else
            {

                pnl.bro=   x.SELLBRO['#text']?x.SELLBRO['#text']:"0";
                pnl.type = "SELL";

            }
            pnl.cpty = x.CPTY['#text']?x.CPTY['#text']:"NA";
            pnl.gbp = 0;
            if ( pnl.valid == "T")
            {
                pnl.gbp = (pnl.fee / pnl.fx).toFixed(2) ;
            } 

            totalpnl += parseFloat(pnl.gbp);
            pnlbybroker.push( pnl )

        });


        pnlbybroker.forEach( d => { 
            d.gbp = +d.gbp     
        });

        let summarydata = d3.nest().key(d => d.broker)
        .rollup( v => d3.sum(v, d => d.gbp ).toFixed(2))
        .entries(pnlbybroker.filter(d => d.valid =="T"));
        
        setData(summarydata);

      });


    }, []);
    
    return data;
};


function xmlToJson(xml) {
	
	// Create the return object
	var obj = {};

	if (xml.nodeType == 1) { // element
        // do attributes
        //console.log("Type 1 Found");
		if (xml.attributes.length > 0) {
		obj["@attributes"] = {};
			for (var j = 0; j < xml.attributes.length; j++) {
				var attribute = xml.attributes.item(j);
				obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
			}
		}
	} else if (xml.nodeType == 3) { // text
	//} else { // text
    obj = xml.nodeValue;
    //console.log(obj);
	}

	// do children
	if (xml.hasChildNodes()) {
		for(var i = 0; i < xml.childNodes.length; i++) {
			var item = xml.childNodes.item(i);
			var nodeName = item.nodeName;
			if (typeof(obj[nodeName]) == "undefined") {
				obj[nodeName] = xmlToJson(item);
			} else {
				if (typeof(obj[nodeName].push) == "undefined") {
					var old = obj[nodeName];
					obj[nodeName] = [];
					obj[nodeName].push(old);
				}
				obj[nodeName].push(xmlToJson(item));
			}
		}
	}
	return obj;
};
