import React, { Component } from 'react'
import * as d3 from 'd3'
import {scaleLinear,max,scaleBand,axisLeft,axisBottom,format} from 'd3';
import ReactDOM from 'react-dom';
//import './App.css';

const width = window.innerWidth;
const height = window.innerHeight;
const centerX = width / 2;
const centerY = height / 2;
const strokeWidth = 20;
const eyeOffsetX = 90;
const eyeOffsetY = 100;
const eyeRadius = 40;
const mouthWidth = 20;
const mouthRadius = 140;

interface Data {
    key: string;
    value: number;
  }


export default class PnlBarChart extends Component {

    componentDidMount() {
        
        this.drawBarChart(this.props.pnldata)
    }

    drawBarChart(pnldata)  {
        
        pnldata.forEach( d => {
            d.value = +d.value;
        });

        pnldata.sort( function(a,b){ return b.value - a.value} );

        const xValue = d => d.value;
        const yValue = d => d.key;
        const margin = {top:100, right:100, bottom:100, left:100};
        const innerWidth =  `${width}` - margin.left - margin.right;
        const innerHeight =  `${height}` - margin.top - margin.bottom;

        console.log(pnldata);
        const svg = d3.select(this.refs.canvas).append("svg");
        svg.attr("width", `${width}`)
        svg.attr("height", `${height}`)
        svg.style("border", "1px solid black")
       
        const xScale = scaleLinear()
            .domain([0, max(pnldata, xValue)])
            .range([0, innerWidth]);


        const yScale = scaleBand()
            .domain(pnldata.map(yValue))
            .range([0,innerHeight])
            .padding(0.2);

        
        //const yAxis = axisLeft(yScale);
        const xAxis = axisBottom(xScale);
            //.tickFormat(format('.3s'))

        const xAxisTickFormat = number => 
            format('s')(number)
            .replace(",","-");

        const g = svg.append('g')
            .attr('transform',`translate(${margin.left},${margin.top})`);

        g.append('g')
            .call(axisLeft(yScale))
            .selectAll('.domain, .tick line')
              .remove();


        g.append('g').call(xAxis)
            .attr('transform',`translate(0,${innerHeight})`)
            .select('.domain')
            .remove();
        
        g.selectAll('rect').data(pnldata)
        .enter().append('rect')
        .attr('y', d => yScale(yValue(d)))
        .attr('width', d => xScale(xValue(d)))
        .attr('height',yScale.bandwidth());

        g.append('text')
            .attr('y',-10)
           .text('PnL');

	    
    }
    
    render() { return <div id="chart" ref="canvas"></div> }
}
